<template>
  <element />
</template>

<script setup>
import { h } from 'vue';

import blogListing from './section/blog-listing.vue';
import card from './section/card';
import download from './section/download';
import glassdoor from './section/glassdoor.vue';
import gradientCallOut from './section/gradient-call-out';
import gradientSlider from './section/gradient-slider';
import gridCardGradient from './section/grid-card-gradient.vue';
import gridCardImage from './section/grid-card-image.vue';
import heading from './section/heading';
import hero from './section/hero';
import karaoke from './section/karaoke';
import newsletter from './section/newsletter.vue';
import parallaxCards from './section/parallax-cards';
import scrollingCards from './section/scrolling-cards.vue';
import sliderBottomHeading from './section/slider-bottom-heading.vue';
import sliderTopHeading from './section/slider-top-heading.vue';
import socialLinks from './section/social-links.vue';
import storiesContainer from './section/stories-container';
import ttfPromo from './section/ttf-promo';
import typoCallOut from './section/typo-call-out.vue';
import video from './section/video.vue';
import scrollingStory from './section/scrolling-story.vue';
import textBlock from './section/text-block.vue';
import cardFullWidth from './card/full-width.vue';
import bulletPoints from './section/bullet-points.vue';
import linkList from './section/link-list.vue';
import iconList from './section/icon-list.vue';
import tabs from './section/tabs.vue';
import cardList from './section/card-list.vue';
import jobList from './section/job-list.vue';
import jobNewsletter from './section/job-newsletter.vue';
import accordionBlock from './section/accordion-block.vue';
import numbers from './section/numbers.vue';
import locationMap from './section/location-map.vue';
import locationSlider from './section/location-slider.vue';
import content from './section/content.vue';
import anchor from './section/anchor.vue';
import stickyMenu from './section/sticky-menu.vue';
import emailForm from './section/email-form/form.vue';

const props = defineProps({
  element: {
    type: Object,
    required: true
  }
});

async function resolve(h, el) {
  if (el.component_type === 'hero') {
    return h(hero, el);
  } else if (el.component_type === 'karaoke') {
    return h(karaoke, el);
  } else if (el.component_type === 'ttf_promo') {
    return h(ttfPromo, el);
  } else if (el.component_type === 'gradient_call_out') {
    return h(gradientCallOut, el);
  } else if (el.component_type === 'parallax_scrolling_cards') {
    return h(parallaxCards, el);
  } else if (el.component_type === 'slider_bottom_heading') {
    return h(sliderBottomHeading, el);
  } else if (el.component_type === 'typo_call_out') {
    return h(typoCallOut, el);
  } else if (el.component_type === 'slider_top_heading') {
    return h(sliderTopHeading, el);
  } else if (el.component_type === 'grid_card_gradient') {
    return h(gridCardGradient, el);
  } else if (el.component_type === 'social_links') {
    return h(socialLinks, el);
  } else if (el.component_type === 'newsletter') {
    return h(newsletter, el);
  } else if (el.component_type === 'download') {
    return h(download, el);
  } else if (el.component_type === 'video') {
    return h(video, el);
  } else if (el.component_type === 'grid_card_image') {
    return h(gridCardImage, el);
  } else if (el.component_type === 'heading') {
    return h(heading, el);
  } else if (el.component_type === 'stories_container') {
    return h(storiesContainer, el);
  } else if (el.component_type === 'card') {
    return h(card, el);
  } else if (el.component_type === 'gradient_slider') {
    return h(gradientSlider, el);
  } else if (el.component_type === 'glassdoor') {
    return h(glassdoor, el);
  } else if (el.component_type === 'scrolling_cards') {
    return h(scrollingCards, el);
  } else if (el.component_type === 'scrolling_story') {
    return h(scrollingStory, el);
  } else if (el.component_type === 'blog_listing') {
    return h(blogListing, el);
  } else if (el.component_type === 'text_block') {
    return h(textBlock, el);
  } else if (el.component_type === 'full_width_card') {
    return h(cardFullWidth, el);
  } else if (el.component_type === 'bullet_points') {
    return h(bulletPoints, el);
  } else if (el.component_type === 'link_list') {
    return h(linkList, el);
  } else if (el.component_type === 'icon_list') {
    return h(iconList, el);
  } else if (el.component_type === 'tabs') {
    return h(tabs, el);
  } else if (el.component_type === 'card_list') {
    return h(cardList, el);
  } else if (el.component_type === 'job_list') {
    return h(jobList, el);
  } else if (el.component_type === 'job_newsletter') {
    return h(jobNewsletter, el);
  } else if (el.component_type === 'accordion_block') {
    return h(accordionBlock, el);
  } else if (el.component_type === 'counter') {
    return h(numbers, el);
  } else if (el.component_type === 'location_map') {
    return h(locationMap, el);
  } else if (el.component_type === 'location_slider') {
    return h(locationSlider, el);
  } else if (el.component_type === 'content') {
    return h(content, el);
  } else if (el.component_type === 'anchor') {
    return h(anchor, el);
  } else if (el.component_type === 'sticky_menu') {
    return h(stickyMenu, el);
  } else if (el.component_type === 'email_form') {
    return h(emailForm, el);
  }
}

const element = await resolve(h, props.element);
</script>
